import React, { useState, useEffect } from 'react';
import "../CSS/PrecisaMIS.css";

const PrecisaIcons = () => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [expertlyOverlayOpen, setExpertlyOverlayOpen] = useState(false);
  const [techOverlayOpen, setTechOverlayOpen] = useState(false);
  const [investmentOverlayOpen, setInvestmentOverlayOpen] = useState(false);
  const [solutionOverlayOpen, setSolutionOverlayOpen] = useState(false);
  const [satisfactionOverlayOpen, setSatisfactionOverlayOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentTechImageIndex, setCurrentTechImageIndex] = useState(0);
  const [currentInvestmentImageIndex, setCurrentInvestmentImageIndex] = useState(0);
  const [currentSolutionImageIndex, setCurrentSolutionImageIndex] = useState(0);
  const [currentSatisfactionImageIndex, setCurrentSatisfactionImageIndex] = useState(0);

  const expertlyImages = [
    "/Images/precisa-overview/precisa-14.jpg",
    "/Images/precisa-overview/precisa-15.jpg",
    "/Images/precisa-overview/precisa-16.jpg"
  ];

  const techImages = [
    "/Images/precisa-overview/precisa-19.jpg",
    "/Images/precisa-overview/precisa-20.jpg",
    "/Images/precisa-overview/precisa-21.jpg"
  ];
  const investmentImages = [
    "/Images/precisa-overview/precisa-8 (1).jpg",
    "/Images/precisa-overview/precisa-9 (1).jpg",
    "/Images/precisa-overview/precisa-10 (1).jpg",
    "/Images/precisa-overview/precisa-11 (1).jpg",
    "/Images/precisa-overview/precisa-12 (1).jpg",
    "/Images/precisa-overview/precisa-13 (1).jpg"
  ];
  const solutionImages = [
    "/Images/precisa-overview/precisa-22.jpg",
    "/Images/precisa-overview/precisa-23.jpg",
    "/Images/precisa-overview/precisa-24.jpg",
    "/Images/precisa-overview/precisa-25.jpg",
    "/Images/precisa-overview/precisa-26.jpg",
    "/Images/precisa-overview/precisa-27.jpg",
    "/Images/precisa-overview/precisa-28.jpg",
    "/Images/precisa-overview/precisa-29.jpg",
    "/Images/precisa-overview/precisa-30.jpg",
  ];
  const satisfactionImages = [
    "/Images/precisa-overview/precisa-1.jpg",
    "/Images/precisa-overview/precisa-2.jpg",
    "/Images/precisa-overview/precisa-3.jpg",
    "/Images/precisa-overview/precisa-4.jpg",
    "/Images/precisa-overview/precisa-5.jpg",
    "/Images/precisa-overview/precisa-6.jpg",
    "/Images/precisa-overview/precisa-7.jpg"
  ];

  const toggleOverlay = () => {
    setOverlayOpen(!overlayOpen);
    setExpertlyOverlayOpen(false);
    setTechOverlayOpen(false);
  };

  const toggleExpertlyOverlay = () => {
    setExpertlyOverlayOpen(!expertlyOverlayOpen);
    if (!expertlyOverlayOpen) {
      setOverlayOpen(false);
    }
  };

  const toggleTechOverlay = () => {
    setTechOverlayOpen(!techOverlayOpen);
    if (!techOverlayOpen) {
      setOverlayOpen(false);
    }
  };
  const toggleInvestmentOverlay = () => {
    setInvestmentOverlayOpen(!investmentOverlayOpen)
    if(!investmentOverlayOpen) {
      setOverlayOpen(false);
    }
  };
  const toggleSolutionOverlay = () => {
    setSolutionOverlayOpen(!solutionOverlayOpen)
    if(!solutionOverlayOpen) {
      setOverlayOpen(false);
    }
  };
  const toggleSatisfactionOverlay = () => {
    setSatisfactionOverlayOpen(!satisfactionOverlayOpen)
    if(!satisfactionOverlayOpen) {
      setOverlayOpen(false);
    }
  }

  const nextImage = (overlay) => {
    if (overlay === 'expertly') {
      setCurrentImageIndex((currentImageIndex + 1) % expertlyImages.length);
    } else if (overlay === 'tech') {
      setCurrentTechImageIndex((currentTechImageIndex + 1) % techImages.length);
    } else if (overlay === 'investment') {
      setCurrentInvestmentImageIndex((currentInvestmentImageIndex +1) % investmentImages.length);
    } else if (overlay === 'solution') {
      setCurrentSolutionImageIndex((currentSolutionImageIndex + 1) % solutionImages.length);
    } else if (overlay === 'satisfaction') {
      setCurrentSatisfactionImageIndex((currentSatisfactionImageIndex + 1) % satisfactionImages.length)
    }
  };

  const prevImage = (overlay) => {
    if (overlay === 'expertly') {
      setCurrentImageIndex((currentImageIndex - 1 + expertlyImages.length) % expertlyImages.length);
    } else if (overlay === 'tech') {
      setCurrentTechImageIndex((currentTechImageIndex - 1 + techImages.length) % techImages.length);
    } else if (overlay === 'investment') {
      setCurrentInvestmentImageIndex((currentInvestmentImageIndex - 1 + investmentImages.length) % investmentImages.length);
    } else if (overlay === 'solution') {
      setCurrentSolutionImageIndex((currentSolutionImageIndex - 1 + solutionImages.length) % solutionImages.length);
    } else if (overlay ==='satisfaction') {
      setCurrentSatisfactionImageIndex((currentSatisfactionImageIndex - 1 + satisfactionImages.length) % satisfactionImages.length)
    }
  };

  useEffect(() => {
    let interval;
    if (expertlyOverlayOpen) {
      interval = setInterval(() => nextImage('expertly'), 3000);
    } else if (techOverlayOpen) {
      interval = setInterval(() => nextImage('tech'), 3000);
    } else if (investmentOverlayOpen) {
      interval = setInterval(() => nextImage('investment'), 3000);
    } else if (solutionOverlayOpen) {
      interval = setInterval(() => nextImage('solution'), 3000);
    } else if (satisfactionOverlayOpen) {
      interval = setInterval(() => nextImage('satisfaction'), 3000);
    }

    return () => clearInterval(interval);
  }, [
    expertlyOverlayOpen,
    techOverlayOpen,
    investmentOverlayOpen,
    solutionOverlayOpen,
    satisfactionOverlayOpen,
    currentImageIndex,
    currentTechImageIndex,
    currentInvestmentImageIndex,
    currentSolutionImageIndex,
    currentSatisfactionImageIndex
  ]);

  return (
    <div>
      <div className="icons-Precisa">
        <div className="icon1-precisa">
          <a href='./Images/Precisa-MIS (1).pdf' target='_blank'>
            <img
              src="./Images/SVGRepo_iconCarrier (2).png"
              alt="Brochure"
              className="Precisa-Icon"
            />
            <h4 className="icon-precisa-text">Our Brochure</h4>
          </a>
        </div>
        <div className="icon1-precisa">
          <a href='./Images/precisa-mis-newsletter (1).pdf' target='_blank'>
            <img
              src="./Images/Group.png"
              alt="newsLetter"
              className="Precisa-Icon"
            />
            <h4 className="icon-precisa-text">Our Newsletter</h4>
          </a>
        </div>
        <div className="icon1-precisa" onClick={toggleOverlay}>
          <img
            src="./Images/SVGRepo_iconCarrier (3).png"
            alt="Images"
            className="Precisa-Icon"
          />
          <h4 className="icon-precisa-text">Precisa Overview</h4>
        </div>
        <div className="icon1-precisa">
        <a href="https://www.layoutltd.com/precisa/" target='_blank'>
          <img
            src="./Images/video.png"
            alt="Images"
            className="Precisa-Icon"
          />
          <h4 className="icon-precisa-text">Precisa Video</h4>
          </a>
        </div>
      </div>

      {overlayOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <button onClick={toggleOverlay}>Close</button>
            <div className="btns-images">
              <button
                className="title-precisa-overlay"
                onClick={toggleExpertlyOverlay}>
                Expertly engineered
              </button>
              <button
                className="title-precisa-overlay"
                onClick={toggleTechOverlay}>
                Technologically advanced
              </button>
              <button 
                className="title-precisa-overlay"
                onClick={toggleInvestmentOverlay}>
                  An investment in your future</button>
              <button 
                className="title-precisa-overlay"
                onClick={toggleSolutionOverlay}
                 >The solution to your printing industry</button>
              <button 
                className="title-precisa-overlay"
                onClick={toggleSatisfactionOverlay}>
                  100% satisfaction guaranteed</button>
            </div>
          </div>
        </div>
      )}

      {expertlyOverlayOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <button onClick={toggleExpertlyOverlay}>Close</button>
            <div className="image-slider">
              <button className="slider-btn" onClick={() => prevImage('expertly')}>❮</button>
              <img
                src={expertlyImages[currentImageIndex]}
                alt={`expertly-precisa-${currentImageIndex}`}
                className="expertly"
              />
              <button className="slider-btn" onClick={() => nextImage('expertly')}>❯</button>
            </div>
          </div>
        </div>
      )}

      {techOverlayOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <button onClick={toggleTechOverlay}>Close</button>
            <div className="image-slider">
              <button className="slider-btn" onClick={() => prevImage('tech')}>❮</button>
              <img
                src={techImages[currentTechImageIndex]}
                alt={`tech-precisa-${currentTechImageIndex}`}
                className="expertly"
              />
              <button className="slider-btn" onClick={() => nextImage('tech')}>❯</button>
            </div>
          </div>
        </div>
      )}
      {investmentOverlayOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <button onClick={toggleInvestmentOverlay}>Close</button>
            <div className="image-slider">
              <button className="slider-btn" onClick={() => prevImage('investment')}>❮</button>
              <img
                src={investmentImages[currentInvestmentImageIndex]}
                alt={`investment-precisa-${currentInvestmentImageIndex}`}
                className="expertly"
              />
              <button className="slider-btn" onClick={() => nextImage('investment')}>❯</button>
            </div>
          </div>
        </div>
      )}
      {solutionOverlayOpen && (
         <div className="overlay">
         <div className="overlay-content">
           <button onClick={toggleSolutionOverlay}>Close</button>
           <div className="image-slider">
             <button className="slider-btn" onClick={() => prevImage('solution')}>❮</button>
             <img
               src={solutionImages[currentSolutionImageIndex]}
               alt={`solution-precisa-${currentSolutionImageIndex}`}
               className="expertly"
             />
             <button className="slider-btn" onClick={() => nextImage('solution')}>❯</button>
           </div>
         </div>
       </div>
  )}
  {satisfactionOverlayOpen && (
    <div className="overlay">
    <div className="overlay-content">
      <button onClick={toggleSatisfactionOverlay}>Close</button>
      <div className="image-slider">
        <button className="slider-btn" onClick={() => prevImage('satisfaction')}>❮</button>
        <img
          src={satisfactionImages[currentSatisfactionImageIndex]}
          alt={`satisfaction-precisa-${currentSatisfactionImageIndex}`}
          className="expertly"
        />
        <button className="slider-btn" onClick={() => nextImage('satisfaction')}>❯</button>
      </div>
    </div>
  </div>
  )}
    </div>
  );
};

export default PrecisaIcons;
