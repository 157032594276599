import React from "react";
import "../CSS/ContactUs.css";
import Map from "./Map";

const Contactmap = () => {
  return (
    <div>
      <div class="map-title-contact">
        <h1 class="PrecisaTitle">Our Presence</h1>
        <hr class="line" />
        <Map />
      </div>
    </div>
  );
};

export default Contactmap;
