import React from 'react';
import History from '../Components/History';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import AboutIcons from '../Components/AboutIcons';

const Company = () => {
  return (
    <div>
      <NavBar/>
      <History/>
      <AboutIcons/>
      <Footer/>
    </div>
  )
}

export default Company
