import React from 'react';
import '../CSS/HomePage.css';
import NavBar from "../Components/NavBar";
import Header from "../Components/Header";
// import Slider from "../Components/Slider";
import Footer from "../Components/Footer";
import Slider1 from '../Components/Slider1';
import slides from "../Components/sliderData.json";


const HomePage = () => {
  return (
    <div>
     <NavBar/>
     <Header/>
     {/* <Slider/> */}
     <Slider1 data={slides.slides} />
     <Footer/>
    </div>
  )
}

export default HomePage




