import React from 'react';
import "../CSS/PrecisaMIS.css";
import { Link } from 'react-router-dom';

const PrecisaHeader = () => {
  return (
    <div>
      <div class="Precisa">

<div class="precisaImage-container">
    <h1 class="PrecisaTitle">
        Precisa MIS
    </h1>
    <hr class="line"/>
    <img 
    src="./Images/Hs4-p-1080.jpeg" 
    alt="" 
    class="PrecisaImg"
    />
</div> 

</div>
    </div>
  )
}

export default PrecisaHeader
