import React from 'react';
import "../CSS/Company.css";

const History = () => {
  return (
    <div>
      <div class="Precisa">

<div class="precisaImage-container">
    <h1 class="PrecisaTitle">
        Company
    </h1>
    <hr class="line"/>   
    <div class="first-part-company">
        <p class="text-1-company">
            Since 1989, Layout Ltd. has been a leader 
            in desktop publishing software development.
             Through the years, Layout has achieved a reputation
              of being a solution-oriented company, emphasizing on 
              the development and adaptation of systems and software 
              for the international market. Specialized in vertical markets, 
              Layout particularly emphasizes on the prepress and print 
              production management markets, as it provides publishers with
               highly needed and customizable solutions on both Macintosh and
                Windows operating systems. 
        </p>
        <img src="./Images/GM_building 3.png" alt="company" class="image-comapny"/>
    </div>
    <p class="text-2-company">
        With diligence and proactive thinking, Layout is at the forefront of technology. 
        It maintains its leadership with a rich product line ranging from non-Latin 
        language-support applications to professional prepress and printing solutions.
         Layout's mission is to enrich the DTP industry with the latest technological 
         software innovations and to respond to the most demanding publishing needs for
          flexible solutions. In 1992, Layout became a QuarkXPress XTensions developer. 
          Layout has developed an Arabic solution for QuarkXPress and was appointed “QuarkXPress Distributor
           for the Middle East” in 1993. Three years later, Layout became QuarkXPress' 
           Publishing System Integrator for the Middle East. <br/><br/>
           Today, Layout continues to build a strong reputation for being a solution-oriented company. 
           Layout has expanded its activities to become not only a developer of desktop publishing software, 
           but also a provider of workflow solutions for industries.
    </p>
</div> 
</div>
    </div>
  )
}

export default History
