import './App.css';
import HomePage from "./Pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrecisaMIS from './Pages/PrecisaMIS';
import Company from './Pages/Company';
import ContactUs from './Pages/ContactUs';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={ <> <HomePage/> </>} />
        <Route path="/precisaMIS" element={ <> <PrecisaMIS/> </>} />
        <Route path="/company" element={ <> <Company/> </>} />
        <Route path="/contact" element={ <> <ContactUs/> </>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
