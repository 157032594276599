import React from 'react';
import ContactForm from '../Components/Contact-Form';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import Contactmap from '../Components/Contact-map';

const ContactUs = () => {
  return (
    <div>
      <NavBar/>
      <ContactForm/>
      <Contactmap/>
      <Footer/>
      
    </div>
  )
}

export default ContactUs
