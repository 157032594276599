import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../CSS/NavBar.css";

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [activeLink, setActiveLink] = useState([]); 

  const handleClick = (link) => {
  setActiveLink(link);
  if (clicked) {
    setClicked(false); 
  }
};


  return (
    <nav className="NavBarItems">
      <Link
      to="/">
      <img 
      src="./Images/Layout-Logo-Low-Res-No-Bg 3.png" 
      alt="Logo" 
      className="logoNavbar" 
      />
      </Link>
      <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-item">
          <Link
            to="/"
            className={activeLink === '/' ? 'nav-links active' : 'nav-links'}
            onClick={() => handleClick('/')}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/company"
            className={activeLink === '/company' ? 'nav-links active' : 'nav-links'}
            onClick={() => handleClick('/company')}
          >
            Company
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/precisaMIS"
            className={activeLink === '/precisaMIS' ? 'nav-links active' : 'nav-links'}
            onClick={() => handleClick('/precisaMIS')}
          >
            PrecisaMIS
          </Link>
        </li>
        <li className="nav-item">
          <a href='https://www.layoutltd.com/products.php#publishing-tools'
          target="_blank" 
         rel="noopener noreferrer"
            className={activeLink === '/precisaMIS' ? 'nav-links active' : 'nav-links'}
          >
            Publishing solutions
          </a>
        </li>
        <li className="contact-us-button">
          <img src="./Images/contact-us-navbar.png" alt="" className="contact-us-envelope" />
          <Link
            to="/contact"
            className= "contact-link-navbar"
            onClick={() => handleClick('/contact')}
          >
            Contact us
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
