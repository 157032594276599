import React from 'react'

const AboutIcons = () => {
  return (
    <div>
      <div className="icons-container-company">
        <div className="goals">
          <img src="./Images/check.png" 
              alt="Goals" 
              className="icon-company"
          />
          <p className="icon-title-company">
            Goals and Motivation
          </p>
          <p className="desc-company">
            We always aim to get better by learning from 
            the past and preparing for the future. 
            We're driven by the fact that our work quality 
            directly influences our clients' success.
          </p>
        </div>
        <div className="goals">
         <img src="./Images/screen.png" 
            alt="vision" 
            className="icon-company"
         />
            <p className="icon-title-company">
              Vision and Standards
          </p>
          <p className="desc-company">
            We aim to be the top choice for advanced 
            software and solutions in printing and publishing.
            Our commitment to superior quality ensures customer
              satisfaction and trust.
          </p>
        </div>
        <div className="goals">
         <img src="./Images/print.png" 
          alt="Values" 
          className="icon-company"
         />
          <p className="icon-title-company">
              Core Values
          </p>
          <p className="desc-company">
          We prioritize exceptional customer care by listening to
           and exceeding client expectations. As we expand, we continually
            refine processes while upholding quality, efficiency, unity, teamwork,
             and integrity throughout our company.
          </p>
        </div>
        <div className="goals">
         <img src="./Images/light.png" 
            alt="Principles" 
            className="icon-company"
         />
          <p className="icon-title-company">
             Guiding Principles
          </p>
          <p className="desc-company">
          Creativity is a powerful force in the software industry. 
          We are always open to exploring new ideas and applying resources. 
          Our future success depends on creativity, continuous learning and
           quality at every stage of what we do.
          </p>

        </div>
      </div>
    </div>
  )
}

export default AboutIcons
