import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../CSS/slider.css";


const Slider1 = ({ data }) => {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide(prev => (prev === data.length - 1 ? 0 : prev + 1));
        }, 8000); 

        return () => clearInterval(interval); 
    }, [data.length]);

    const nextSlide = () => {
        setSlide(slide === data.length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? data.length - 1 : slide - 1);
    };

    return (
        <div>
       <div class="map-title-contact">
        <h1 class="PrecisaTitle">Our Products</h1>
        <hr class="line" />
        </div>
        <div className='carousel'>
            <span
                className="prev"
                onClick={prevSlide}
            >
                &#10094;
            </span>
            {data && data.map((itm, index) => (
                <div key={index} className={slide === index ? "slide-carousel" : "slide-carousel slide-carousel-hidden"}>
                    <img
                        src={itm.src}
                        alt={itm.alt}
                    />
                    <div className="text-btn-slider">
                        <p className={slide === index ? "carousel-text" : "carousel-text carousel-text-hidden"}>{itm.text}</p>
                        {itm.buttonslider === "Precisa MIS" ? (
                            <Link to="/PrecisaMis">
                                <button className={slide === index ? "btn-carousel" : "btn-carousel btn-carousel-hidden"}>
                                    {itm.buttonslider}
                                </button>
                            </Link>
                        ) : (
                            <a href="https://www.layoutltd.com/products.php#publishing-tools" target="_blank" rel="noopener noreferrer">
                                <button className={slide === index ? "btn-carousel" : "btn-carousel btn-carousel-hidden"}>
                                    {itm.buttonslider}
                                </button>
                            </a>
                        )}
                    </div>
                </div>
            ))}
            <button
                className="next"
                onClick={nextSlide}
            >
                &#10095;
            </button>
            <span className='indicators'>
                {data && data.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setSlide(index)}
                        className={slide === index ? "indicator" : "indicator indicator-inactive"}
                    >
                        .
                    </button>
                ))}
            </span>
        </div>
        </div>
    );
};

export default Slider1;
