import React, { useRef } from "react";
import "../CSS/ContactUs.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div>
      <div class="Precisa">
        <div class="precisaImage-container">
          <h1 class="PrecisaTitle">Anything in Mind?</h1>
          <hr class="line" />
          <div className="left-right-contact">
            {/* left-side */}
            <div className="left-contact-form">
              <form
                className="form-contact"
                ref={form}
                onSubmit={handleFormSubmit}
              >
                <div className="label-input-contact">
                  <label className="label-contact" htmlFor="name">
                    Full Name:
                  </label>
                  <input
                    className="input-contact"
                    type="text"
                    id="name"
                    name="name"
                    required
                  />
                </div>
                <div className="label-input-contact">
                  <label className="label-contact" htmlFor="email">
                    Email:
                  </label>
                  <input
                    className="input-contact"
                    type="email"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="label-input-contact">
                  <label className="label-contact" htmlFor="message">
                    Enter your message here...
                  </label>
                  <textarea
                    className="input-contact-textarea"
                    id="message"
                    name="message"
                    // placeholder="Enter your message here..."
                    required
                  ></textarea>
                </div>
                <button className="submit-contact" type="submit">
                  Submit
                </button>
              </form>
            </div>
            {/* right-side */}
            <div className="right-info">
              <p className="info-text-contact">
                Layout Sarl Habis Technology Center Street 50, Sector 2, Jisr El
                Bacha P.O Box 45-266 HazmiehBeirut - Lebanon
              </p>
              <div className="icon-text-contact">
                <img
                  src="./Images/Contact/mail-contact.png"
                  alt="mail"
                  className="icon-contact"
                />
                <h4 className="text-contact">Email: sales@layoutltd.com</h4>
              </div>
              <div className="icon-text-contact">
                <img
                  src="./Images/Contact/phone-contact.png"
                  alt="mail"
                  className="icon-contact"
                />
                <h4 className="text-contact">Telephone: +961.1.512.572</h4>
              </div>
              <div className="icon-text-contact">
                <img
                  src="./Images/Contact/phone-contact.png"
                  alt="mail"
                  className="icon-contact"
                />
                <h4 className="text-contact">Mobile: +961.3.348260</h4>
              </div>
              <div className="icon-text-contact">
                <img
                  src="./Images/Contact/phone-contact.png"
                  alt="mail"
                  className="icon-contact"
                />
                <h4 className="text-contact">Fax: +961.1.512.570</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
