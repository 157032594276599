import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div>
      <div className="img-btns-header">
      <img 
        src="./Images/Untitled-1 1.png" 
        alt="collage" 
        className="collage-header" 
        />
      {/* <div className="btns-header">
        <Link to="./PrecisaMis">
        <button 
        className="precisa">
          Precisa MIS</button>
        </Link>
       <a href="https://www.layoutltd.com/products.php#publishing-tools"
         target="_blank" 
         rel="noopener noreferrer"
         >
        <button 
        className="publishing">
          Publishing</button>
       </a>
          
      </div> */}

      </div>
    </div>
  )
}

export default Header
