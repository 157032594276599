import React, { useState, useRef  } from 'react';
import "../CSS/PrecisaMIS.css";

function Accordion(props) {
  const [isActive, setIsActive] = useState(false);
  const accordionRef = useRef(null); 

  const toggleAccordion = () => {
    setIsActive(!isActive);
    if (!isActive) {
      accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className={`wrapper-faq ${isActive ? 'active' : ''}`} ref={accordionRef}>
      <button className="accordion-precisa" onClick={toggleAccordion} >
        {props.title}
        <span className="arrow-icon">{isActive ? '▲' : '▼'}</span>
      </button>
      <div className="pannel-faq" style={{ display: isActive ? 'block' : 'none' }} >
        {props.children}
      </div>
    </div>
  );
}

function FAQ() {
  return (
    <div className="FAQ">
      <Accordion title="Modular Structure">
        <div className="text-precisa">
          <ul className="text-precisa">
            <li className="list-text-precisa">Centralized customer database for efficient access to contact details</li>
            <li className="list-text-precisa">Integrated cost estimation and imposition features for accurate pricing</li>
            <li className="list-text-precisa">Quick quotation generation with exact profit margin calculation</li>
            <li className="list-text-precisa">Visual job tracking module for monitoring works-in-progress</li>
            <li className="list-text-precisa">Workflow assessment and control for efficient production management</li>
            <li className="list-text-precisa">Automatic invoice generation based on quotation data</li>
            <li className="list-text-precisa">Streamlined delivery system with notification capabilities</li>
            <li className="list-text-precisa">Comprehensive reports for customer retention and history tracking</li>
            <li className="list-text-precisa">Customizable reporting for detailed business analysis</li>
            <li className="list-text-precisa">Integration with full functional accounting system for seamless operations</li>
          </ul>
        </div>
      </Accordion>
      <Accordion title="Precisa MIS Description">
        <p className="text-precisa">
          In today's fast moving economy, it is not enough to just deliver on time. Operating costs are on the rise and competition is hard. To stay ahead of the game, you have to manage your costs and increase your margins. Even the smallest costs need to be taken into account.<br/><br/>
          PRECISA MIS is the latest cutting edge cost estimation software specifically designed by our team at Layout Ltd. Printing houses use PRECISA MIS to generate quotes and accurately calculate detailed costs, which maximizes productivity. PRECISA MIS manages quotes and cost estimation for offset, silkscreen, and digital printing.<br/><br/>
          Based on your printing plant real data, PRECISA MIS builds a true image of your cost for every job, allowing you to decide on the best choice for your business.
        </p>
      </Accordion>
      <Accordion title="Your Printing Business With Precisa MIS">
      <div className="text-precisa">
          <ul className="text-precisa">
            <li className="list-text-precisa">Investment in Precisa MIS ensures future-proofing and exceptional support for your printing business.</li>
            <li className="list-text-precisa">Detailed cost breakdowns provided by Precisa MIS address volatile cost parameters, enhancing profitability.</li>
            <li className="list-text-precisa">Precisa MIS offers simplicity and flexibility, utilizing real data for accurate calculations and easy configuration.</li>
            <li className="list-text-precisa">Leveraging advanced web technology, Precisa MIS boasts a user-friendly interface and customizable alert system.</li>
            <li className="list-text-precisa">Modular design allows for gradual implementation and scalability to suit your business needs.</li>
            <li className="list-text-precisa">With Precisa MIS, handling unlimited job specs, quantity breaks, and markup points is effortless.</li>
            <li className="list-text-precisa">Efficient job quoting is facilitated through saved job templates, reducing time and effort.</li>
            <li className="list-text-precisa">Automatic generation of job tickets streamlines workflow from approval to delivery and invoicing.</li>
            <li className="list-text-precisa">Precisa MIS empowers cost tracking and comparison, enabling better operational adjustments and margin improvements.</li>
            <li className="list-text-precisa">Overall, Precisa MIS enhances operational visibility, efficiency, and profitability for printing plants.</li>
            <li className="list-text-precisa">Based on your printing plant real data, PRECISA MIS builds a true image of your cost for every job, allowing you to decide on the best choice for your business.</li>
          </ul>
          </div>
      </Accordion>
      <Accordion title="Why Get Precisa MIS?">
      <div className="text-precisa">
          <ul className="text-precisa">
            <li className="list-text-precisa">Reduce Administrative time and errors.</li>
            <li className="list-text-precisa">Fast and easy to use.</li>
            <li className="list-text-precisa">Maintaining consistent price list and suppliers costs.</li>
            <li className="list-text-precisa">Better understand and estimate your cost.</li>
            <li className="list-text-precisa">Web based cost estimating tool.</li>
            <li className="list-text-precisa">Centralized print jobs for easy information access.</li>
            <li className="list-text-precisa">Save Time & Money.</li>
            <li className="list-text-precisa">Compatible with Mac and Windows platforms.</li>
            
          </ul>
          </div>
      </Accordion>
      
      

    </div>
  );
}

export default FAQ;
