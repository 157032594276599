import React, { useState } from 'react';
import "../CSS/Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
    const [clicked, setClicked] = useState(false);
    const [activeLink, setActiveLink] = useState([]); 
    const [showEmail, setShowEmail] = useState(false);
    const [showPhone, setShowPhone] = useState(false);

  const handleClick = (link) => {
  setActiveLink(link);
  if (clicked) {
    setClicked(false); 
  }
};
const toggleEmail = () => {
    setShowEmail(!showEmail);
  };
const togglePhone = () => {
    setShowPhone(!showPhone);
};
const handleLogoClick = () => {
  window.scrollTo(0, 0);
};
  return (
    <div>
      <div class="Footer-container">
        <div class="menu-footer">
            <ul class="menu-list">
                <li class="menu-list-title">
                <Link
                    to="/"
                    className='footer-menu-name'
                    onClick={() => handleClick('/')}
                >
                Home
                </Link>
                </li>
                <li class="menu-list-title">
                <Link
                    to="/company"
                    className='footer-menu-name'
                    onClick={() => handleClick('/company')}
                >
                Company
                </Link>
                </li>
                <li class="menu-list-title">
                <Link
                    to="/precisaMIS"
                    className='footer-menu-name'
                    onClick={() => handleClick('/precisaMIS')}
                >
                PrecisaMIS
                </Link>
                </li>
                <li class="menu-list-title">
                <Link
                    to="/contact"
                    className='footer-menu-name'
                    onClick={() => handleClick('/contact')}
                >
                Contact us
                </Link>
                </li>
            </ul>
        </div>
        <hr class="line-footer"/>
        <Link to="/"  onClick={handleLogoClick}>
        <img 
            src="./Images/Layout-Logo-Low-Res-No-Bg 3.png" 
            alt="layout-logo" 
            class="Layout-logo-footer" 
            />
        </Link>
        <hr class="line-footer"/>
        <div class="mail-phone-icons-footer">
        <img
            src="./Images/Mail-footer.png"
            alt="mail"
            className="icon-footer"
            style={{ cursor: 'pointer', filter: showEmail ? 'invert(63%) sepia(92%) saturate(4528%) hue-rotate(395deg) brightness(85%) contrast(95%)' : 'none' }}
            onClick={toggleEmail}
          />
          {showEmail && <p className="phone_footer">sales@layoutltd.com</p>}
            <img 
            src="./Images/Phone-footer.png" 
            alt="phone" 
            class="icon-footer"
            style={{cursor: 'pointer', filter: showPhone ? 'invert(63%) sepia(92%) saturate(4528%) hue-rotate(395deg) brightness(85%) contrast(95%)' : 'none'}}
            onClick={togglePhone}
            />
            {showPhone && <p className="phone_footer">+961.1.512.572</p>}
        </div>
    </div>
    <p class="copyright">
        Copyright ©  2024 Layout Ltd. All rights reserved.
    </p>
    </div>
  )
}

export default Footer
