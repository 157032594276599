import React from 'react';
import PrecisaHeader from '../Components/Precisa-Header';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import PrecisaIcons from '../Components/Precisa-Icons';
import PrecisaFAQ from '../Components/Precisa-FAQ';
const PrecisaMIS = () => {
  return (
    <div>
      <NavBar/>
      <PrecisaHeader/>
      <PrecisaIcons/>
      <PrecisaFAQ/>
      <Footer/>
    </div>
  )
}

export default PrecisaMIS
